import React, {
  useState,
  useRef,
  useEffect
} from 'react';
import cx from 'classnames';
import {
  bool as boolType,
  string as stringType,
  func,
  any
} from 'prop-types';
import { useStore } from '@thd-nucleus/experience-context';
import { Loader } from '@thd-olt-component-react/core-ui';
import {
  QueryProvider,
  extend,
  string,
  shape,
  params,
  arrayOf
} from '@thd-nucleus/data-sources';
import { InstantRecsProductPod } from '@thd-olt-component-react/product-pod-v6';
import { CarouselHeader } from '../core/CarouselHeader';
import { LoadingPlaceholder } from '../core/LoadingPlaceholder';
import { LIKE, DISLIKE } from '../core/constants';
import '../../styles/thd-recs-containers.scss';
import styles from './InstantRecs.module.scss';

const setAsyncTimeout = (cb, timeout = 0) => new Promise((resolve) => {
  setTimeout(() => {
    cb();
    resolve();
  }, timeout);
});

export const InstantRecsPlaceholder = () => {
  return (
    <LoadingPlaceholder />
  );
};

const InstantRecs = (props) => {

  const {
    schemaName,
    data,
    showLoading,
    loading,
    hasNoRecs,

    resetRecs,
    onLikeClick,
    onDislikeClick
  } = props;

  const [replacedProds, setReplacedProds] = useState([]);
  const [curReplProds, setCurReplProds] = useState([]);
  const [currRecs, setCurrRecs] = useState([]);
  const { storeId } = useStore();
  const likedProds = useRef([]);
  const interactionsStack = useRef([]);

  const getCurrRecs = () => currRecs.map((prod) => prod.product.itemId);

  const onLike = (itemId) => {

    // if unliked, remove itemId from liked array
    // and ignore click event
    const lProds = likedProds.current;
    if (lProds?.includes(itemId)) {
      likedProds.current = lProds.filter((id) => id !== itemId);
      return;
    }

    if (loading || curReplProds?.length) {
      interactionsStack.current.push({ itemId, inter: LIKE });
      return;
    }

    likedProds.current = [...lProds, itemId];
    onLikeClick({
      itemId,
      likedProducts: likedProds.current,
      replacedProducts: replacedProds,
      currRecs: getCurrRecs()
    });
  };

  const onDisLike = (itemId) => {

    if (loading || curReplProds?.length) {
      interactionsStack.current.push({ itemId, inter: DISLIKE });
      return;
    }

    // if a previously liked rec is disliked
    // remove it from liked list
    const lProds = likedProds.current;
    if (lProds?.includes(itemId)) {
      likedProds.current = lProds.filter((id) => id !== itemId);
    }
    onDislikeClick({
      itemId,
      likedProducts: likedProds.current,
      replacedProducts: replacedProds,
      currRecs: getCurrRecs()
    });
  };

  if (!loading && !curReplProds?.length && interactionsStack.current?.length) {
    const { itemId, inter } = interactionsStack.current.shift();

    if (inter === LIKE) {
      onLike(itemId);
    } else {
      onDisLike(itemId);
    }
  }

  const updateRecsInfo = () => {

    // filter out recommendations that do not have price
    let cProds = data?.products.filter((prod) => {
      const priceVal = prod.product?.pricing?.value;

      if (priceVal && priceVal > 0) {
        return prod;
      }
      return false;
    });

    if (currRecs.length === 0) {
      setCurrRecs(cProds);
      return;
    }

    const newRecsIds = cProds.map((prod) => prod.product.itemId);
    let newlyReplaced = getCurrRecs()
      .filter((pId) => !newRecsIds.includes(pId));

    setCurReplProds(newlyReplaced);
    setReplacedProds((prevSt) => [...prevSt, ...newlyReplaced]);

    (async () => setAsyncTimeout(() => {
      setCurrRecs(cProds);
      setCurReplProds([]);
    }, 500))();
  };

  useEffect(() => {
    if (data?.products.length) {
      updateRecsInfo();
      return;
    }

    // if the last recommendation is disliked and there are
    // no more recommendations reset to initial pip recommendations.
    if ((!data || hasNoRecs !== '') && currRecs.length === 1) {
      setReplacedProds([]);
      likedProds.current = [];
      setCurReplProds([]);
      resetRecs();
    }
  }, [data, hasNoRecs]);

  const wrapperClasses = cx(
    'dynamic-recs',
    'sui-p-4',
    schemaName,
    {
      loading: !(currRecs.length) && showLoading && loading
    }
  );

  if (!loading && (!data?.products && !currRecs.length)) {
    return null;
  }

  return (
    <div id={schemaName} className={wrapperClasses} data-type="container" data-component="InstantRecs">
      <meta data-prop="name" content={schemaName} />
      {
        showLoading && loading && !(currRecs.length) && (
          <InstantRecsPlaceholder />
        )
      }
      {
        currRecs?.length > 0
        && (
          <>
            <CarouselHeader
              title="Picks Inspired by You"
              subtitle="Tell us what you like, and we'll find what you love."
            />
            <QueryProvider
              cacheKey={`${schemaName}-grid`}
              dataSource={schemaName}
            >
              <div className="sui-grid sui-grid-cols-2 md:sui-grid-cols-4 xl:sui-grid-cols-6 sui-gap-4 sui-pb-4">
                {
                  currRecs.map((prod, idx) => {
                    const itemId = prod.product.itemId;
                    return (
                      <div className={styles.instantRecsCol}>
                        <div>
                          <InstantRecsProductPod
                            itemId={itemId}
                            storeId={storeId}
                            onLikeClick={onLike}
                            onDislikeClick={onDisLike}
                            liked={likedProds.current.includes(itemId)}
                            position={(idx + 1)}
                          />
                        </div>
                        {
                          curReplProds.includes(itemId)
                            ? (
                              <div className={styles.loaderWrapper}>
                                <Loader active />
                              </div>
                            ) : <></>
                        }
                      </div>
                    );
                  })
                }
              </div>
            </QueryProvider>
          </>
        )
      }
    </div>
  );

};

const instantRecsProductModel = extend({}, {
  itemId: string(),
  dataSource: string()
}, InstantRecsProductPod.dataModel.product);

export const instantRecsDataModel = {
  recs: params({
    anchorId: string().isRequired(),
    apiName: string().isRequired(),
    storeId: string(),
    key: string(),
    instantRec: string(),
    maxResults: string(),
    model: string(),
    tablename: string(),
    appId: string()
  }).shape({
    metadata: shape({
      apiName: string(),
      title: string(),
      version: string(),
      modelName: string(),
      fallbackModelName: string(),
      type: string()
    }),
    products: arrayOf(shape({
      product: params({ dataSource: string() })
        .shape(instantRecsProductModel)
    }))
  })
};

InstantRecs.dataModel = instantRecsDataModel;
InstantRecs.displayName = 'InstantRecs';

InstantRecs.propTypes = {
  schemaName: stringType.isRequired,
  showLoading: boolType,
  loading: boolType,

  // eslint-disable-next-line react/forbid-prop-types
  data: any,
  hasNoRecs: stringType,
  resetRecs: func,
  onDislikeClick: func,
  onLikeClick: func
};

InstantRecs.defaultProps = {
  showLoading: false,
  loading: false,

  data: null,
  hasNoRecs: '',
  resetRecs: () => { },
  onDislikeClick: () => { },
  onLikeClick: () => { }
};

export { InstantRecs };
